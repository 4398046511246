/**
 * @description array length is greater than zero
 * @param {Array} array 
 * @returns {Boolean}
 */
export function arrayIsValid(array) {
  try {
    return Boolean(Array.isArray(array) && array.length > 0);
  } catch (error) {
    console.error(error);
    return false;
  }
}

/**
 * @description create query string from object of filters
 * @param {object} filters 
 * @returns {string}
 */
export function createFiltersQueryString(filters) {
  try {
    const query = [];
    for (let key of Object.keys(filters)) {
      if (filters[key]) {
        if (Array.isArray(filters[key])) {
          if (filters[key].length > 0) {
            query.push(`${key}=${filters[key]}`);
          }
          continue;
        }
        query.push(`${key}=${filters[key]}`);
      }
    }
    return query.length > 0 ? `?${query.join('&')}` : '';
  } catch (error) {
    console.error(error);
    return '';
  }
}
