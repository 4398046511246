import {
  Button,
  Collapse,
  Divider,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MultipeInput from "../../components/utils/MultipleInput";
import countries from "../../Helpers/countries.json";
import convertBase64 from "../../functions/base64converter";
import { BsImages } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import {
  createPost,
  getFormData,
  getDateByYear,
  getBandsByFestival,
  getSectionsByBandDate,
  getTravel,
} from "../../functions/api";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router";
import moment from "moment";
import generateAddressImg from "../../functions/generateAddressImg";

export default function TravelForm(props) {
  const history = useHistory();
  const [sizeMsg, setSizeMsg] = useState(false);
  const [isCarnivalTrip, setCarnivalTrip] = useState(false);
  const [trip, setTrip] = useState({
    name: "",
    img: null,
    countries: null,
    chasers: null,
    from: null,
    to: null,
    desc: "",
    year: null,
    bdId: "",
    fId: "",
    sectionId: "",
  });
  const [form, setForm] = useState({
    years: [],
    chasers: [],
    dates: [],
    bandDates: [],
    sections: [],
    loaded: false,
  });
  const [alert, setAlert] = useState({
    serverity: "",
    title: "",
    message: "",
    open: false,
  });
  const query = new URLSearchParams(props.location.search);
  const id = query.get("id");
  const disabled = Boolean(id);
  async function editTrip(e) {
    const name = e.target?.name || e.detail?.tagify.DOM.originalInput.name;
    const value = e.target?.value || e.detail?.tagify.getCleanValue();
    switch (name) {
      case "year":
        // load dates
        let festivalDates = await getDateByYear(value);
        if (festivalDates.code == 0) {
          setForm({ ...form, dates: festivalDates.data });
        }
        break;
      case "fId":
        // load band dates
        let date = form.dates.find((d) => d.id == value);
        let bandDates = await getBandsByFestival(date?.slug);
        if (bandDates.code == 0) {
          setForm({ ...form, bandDates: bandDates.data });
        }
        break;
      case "bdId":
        // load sections
        let sections = await getSectionsByBandDate(value);
        if (sections.code == 0) {
          setForm({ ...form, sections: sections.data });
        }
        break;
      default:
        break;
    }
    setTrip({ ...trip, [name]: value });
  }
  useEffect(() => {
    loadData();
  }, []);
  const loadData = async () => {
    if (!form.loaded) {
      const res = await getFormData("travel");
      if (res?.code == -1) {
        setAlert({
          ...alert,
          open: true,
          message: "Some thing is wrong.",
          title: "Error",
          serverity: "error",
        });
      } else if (res) {
        setForm({
          years: res.data.years,
          chasers: res.data.chasers,
          loaded: true,
        });
      }
      if (id) {
        let response = await getTravel(id);
        if (response.code == 0) {
          response.data.from = moment(response.data.from).format(
            "YYYY-MM-DDThh:mm"
          );
          response.data.to = moment(response.data.to).format(
            "YYYY-MM-DDThh:mm"
          );
          setTrip(response.data);
        }
      }
    }
  };
  async function submit() {
    if (trip?.name && trip?.countries && trip?.from && trip?.to) {
      if (trip.img) trip.img.base64 = trip.img.base64.split("base64,").pop();
      const res = id
        ? { code: 0 }
        : await createPost("travel", {
            ...trip,
            countries: trip.countries.map((c) => c.value),
            chasers: trip.chasers.map((c) => c.id),
          });
      if (typeof res?.code != "undefined") {
        switch (res.code) {
          case -1:
            setAlert({
              ...alert,
              open: true,
              message: res.message,
              serverity: "error",
              title: "Error",
            });
            break;
          case 1:
            setAlert({
              ...alert,
              open: true,
              message: res.message,
              serverity: "warning",
              title: "Warning",
            });
            break;
          case 0:
            history.state = { code: 201 };
            history.push("/myaccount");
            break;
          default:
            setAlert({ ...alert, open: false });
            setTrip({
              name: "",
              img: null,
              countries: null,
              chasers: null,
              from: null,
              to: null,
              desc: "",
              year: null,
              fId: "",
              bdId: "",
              sectionId: "",
            });
            break;
        }
      }
    } else {
      setAlert({
        ...alert,
        open: true,
        message: "Name, country, from date and to date fields are required.",
        title: "Warning",
        serverity: "warning",
      });
    }
  }
  const styles = {
    input: {
      marginBottom: "2rem",
      marginTop: "1rem",
    },
    representImage: {
      display: "flex",
      flexDirection: "row",
      border: "1px dashed #212121",
      borderRadius: "7px",
    },
    image: {
      margin: "1rem",
    },
    imageBackground: {
      backgroundImage: `url(${trip.img?.data})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      width: "7rem",
      height: "7rem",
      borderRadius: "10px",
    },
  };
  var representImage;
  if (trip.img) {
    representImage = (
      <>
        <br />
        <img
          className="img-fluid"
          src={trip.img?.base64 || generateAddressImg(trip.img?.path, 0, 0)}
          width={200}
          height={200}
        />
        <Button
          style={{
            marginTop: "0.3rem",
            width: "7rem",
          }}
          color="secondary"
          variant="text"
          onClick={() => {
            // remove this image from array
            setTrip({ ...trip, img: null });
          }}
        >
          <IoCloseSharp style={{ fontSize: "1.1rem" }} />
          &nbsp;Remove
        </Button>
      </>
    );
  }
  return (
    <div className="container">
      <div className="card shadow-lg my-5 px-5">
        <h3 className="titleText my-4">{disabled ? "View" : "Add"} Travel</h3>
        <Divider />
        <section>
          <>
            <div className="form-group my-2">
              <InputLabel>Travel Name</InputLabel>
              <TextField
                fullWidth
                color="primary"
                required
                value={trip.name}
                variant="outlined"
                name="name"
                disabled={disabled}
                placeholder="Name"
                onChange={editTrip}
              />
            </div>
            <div className="form-group my-2 mt-3">
              <InputLabel>Upload Image</InputLabel>
              <Button
                className="mt-1 mb-3"
                style={styles.input}
                variant="outlined"
                color="primary"
                component="label"
                disabled={disabled}
              >
                <BsImages />
                &nbsp;Select...
                <input
                  formEncType="multipart/form-data"
                  type="file"
                  hidden
                  accept="image/*,gif/*"
                  onChange={async (e) => {
                    if (e.target.files[0]) {
                      var { name, type, size } = e.target.files[0];
                      if (size > 5242880) {
                        setSizeMsg(true);
                      } else {
                        setSizeMsg(false);
                        var data = await convertBase64(e.target.files[0]);
                        var img = {
                          base64: data,
                          name: name,
                          type: type,
                        };
                        setTrip({ ...trip, img });
                      }
                    }
                  }}
                />
              </Button>
              {sizeMsg ? (
                <div>
                  <small className="col-12 text-danger">
                    Maximum file size: 5MB
                  </small>
                </div>
              ) : (
                ""
              )}
              {representImage}
            </div>
            <MultipeInput
              label="Country"
              name="countries"
              required={true}
              disabled={disabled}
              onChange={editTrip}
              value={trip.countries}
              options={countries}
            />
            <div className="row my-2">
              <div className="form-group col-md-6">
                <InputLabel>Start Date</InputLabel>
                <TextField
                  color="primary"
                  type="datetime-local"
                  value={trip.from}
                  disabled={disabled}
                  variant="outlined"
                  name="from"
                  onChange={editTrip}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <InputLabel>End Date</InputLabel>
                <TextField
                  color="primary"
                  type="datetime-local"
                  value={trip.to}
                  disabled={disabled}
                  variant="outlined"
                  name="to"
                  onChange={editTrip}
                  required
                />
              </div>
            </div>
            <div className="form-group my-2">
              <InputLabel>About this trip</InputLabel>
              <textarea
                fullWidth
                multiline
                placeholder="Summary"
                color="primary"
                value={trip.desc}
                disabled={disabled}
                className="form-control"
                variant="outlined"
                name="desc"
                onChange={editTrip}
                helperText={`${trip.desc.length}/150`}
                inputProps={{
                  maxlength: 150,
                }}
                dir="auto"
              />
            </div>
            <div className="form-group my-2">
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="A Carnival Trip"
                disabled={disabled}
                labelPlacement="A Carnival Trip"
                name="isCarnivalTrip"
                onChange={(event) => setCarnivalTrip(event.target.checked)}
              />
            </div>
            {isCarnivalTrip && (
              <>
                <div className="form-group">
                  <InputLabel>Year</InputLabel>
                  <Select
                    fullWidth
                    name="year"
                    onChange={editTrip}
                    variant="outlined"
                    color="primary"
                    value={trip.year}
                  >
                    {form.years?.map((year, key) => (
                      <MenuItem value={year} key={key}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="form-group">
                  <InputLabel>Carnival</InputLabel>
                  <Select
                    fullWidth
                    name="fId"
                    onChange={editTrip}
                    variant="outlined"
                    color="primary"
                    value={trip.fId}
                    disabled={!trip.year}
                  >
                    {form.dates?.map((date, key) => (
                      <MenuItem value={date.id} key={key}>
                        {date.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="form-group">
                  <InputLabel>Band/Group</InputLabel>
                  <Select
                    fullWidth
                    name="bdId"
                    onChange={editTrip}
                    variant="outlined"
                    color="primary"
                    value={trip.bdId}
                    disabled={!trip.fId}
                  >
                    {form.bandDates?.map((bandDate, key) => (
                      <MenuItem value={bandDate._id} key={key}>
                        {bandDate.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="form-group">
                  <InputLabel>Section</InputLabel>
                  <Select
                    fullWidth
                    name="sectionId"
                    onChange={editTrip}
                    variant="outlined"
                    color="primary"
                    value={trip.sectionId}
                    disabled={!trip.bdId}
                  >
                    {form.sections?.map((section, key) => (
                      <MenuItem value={section._id} key={key}>
                        {section.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="form-group">
                  <MultipeInput
                    label="Chaser"
                    name="chasers"
                    value={trip.chasers}
                    disabled={disabled}
                    onChange={editTrip}
                    options={form.chasers}
                  />
                </div>
              </>
            )}
          </>
          <Collapse className="mb-5" in={alert.open}>
            <Alert
              severity={alert.serverity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setAlert({ ...alert, open: !alert.open })}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle>{alert.title}</AlertTitle>
              {alert.message}
            </Alert>
          </Collapse>
          <Divider flexItem={alert.open} />
          <Button
            variant="outlined"
            type="submit"
            color="primary"
            className="my-3"
            onClick={submit}
          >
            {disabled ? "Return" : "Submit"}
          </Button>
        </section>
      </div>
    </div>
  );
}
